export const SPACE = {
  xs: 1,
  s: 3,
  m: 6,
  l: 8,
  xl: 16,
};
export const PAGEPAD = { x: "15vw", y: "15vh" };
export const IMGSIZE = ["170px", "200px", "230px", "260px"];
export const BIGHEADER = { base: "4xl", md: "6xl" };
